import React, { Suspense } from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { I18nextProvider } from "react-i18next";

import { store } from "store/";
import renderRoutes from "./routes";
import reportWebVitals from "./reportWebVitals";
import ModalsProvider from "components/ModalsProvider";
import i18n from "./i18n";

import "styles/fonts.scss";
import "styles/global.scss";
import "styles/common.scss";
import "styles/tailwind.scss";
import LoadingScreen from "components/LoadingScreen";
import { DebugProvider } from "components/DebugContext/DebugContext";
import NotificationsProvider from "components/NotificationsProvider";
import ChargebeeProvider from "components/ChargebeeProvider";

// Create a clients
const queryClient = new QueryClient();

const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<LoadingScreen />}>
              <DebugProvider>
                <BrowserRouter>{renderRoutes()}</BrowserRouter>
                <ModalsProvider />
                <NotificationsProvider />
                <ChargebeeProvider />
              </DebugProvider>
            </Suspense>
          </I18nextProvider>
        </LocalizationProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
