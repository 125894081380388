import { useEffect } from "react";
import { useDebugMode } from "components/DebugContext/DebugContext";

const ChargebeeProvider = () => {
  const { debugMode } = useDebugMode();
  

  useEffect(() => {

    const init = async () => {
      try {
        const chargebeePublishableKey =
        process.env.REACT_APP_LABLEARNER_CHARGEBEE_PUBLISHABLE_KEY;
        const chargebeeSite = process.env.REACT_APP_LABLEARNER_CHARGEBEE_SITE;
        if (chargebeePublishableKey && chargebeeSite && (window as any).Chargebee) {
          (window as any).Chargebee.init({
            site: chargebeeSite,
            publishableKey: chargebeePublishableKey,
          });
          if (debugMode) {
            console.log("Chargebee initialized");
            console.log("Chargebee site:", chargebeeSite);
            console.log("Chargebee publishable key:", chargebeePublishableKey);          
          }
        }
      } catch (error) {
        if (debugMode) console.error("Error initializing Chargebee:", error);
      }
    };

    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default ChargebeeProvider;